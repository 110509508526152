import type { Story } from '@prezly/sdk';

export type StoryWithImage = Story & Pick<Story.ExtraFields, 'thumbnail_image'>;

export interface BasePageProps {
    translations: Record<string, any>;
    isTrackingEnabled?: boolean;
}

export type SharingPlacement = ('top' | 'bottom')[];

export enum SocialNetwork {
    BLUESKY = 'bluesky',
    FACEBOOK = 'facebook',
    MESSENGER = 'messenger',
    TWITTER = 'twitter',
    THREADS = 'threads',
    TELEGRAM = 'telegram',
    WHATSAPP = 'whatsapp',
    LINKEDIN = 'linkedin',
    MASTODON = 'mastodon',
    PINTEREST = 'pinterest',
    REDDIT = 'reddit',
}

export type StoryActions = Pick<
    ThemeSettings,
    'showCopyContent' | 'showDownloadAssets' | 'showCopyUrl'
>;

export interface ThemeSettingsApiResponse {
    accent_color: string;
    header_background_color: string;
    header_link_color: string;
    show_date: boolean;
    show_subtitle: boolean;
    sharing_actions: SocialNetwork[];
    sharing_placement: SharingPlacement;
    show_download_assets: boolean;
    show_copy_content: boolean;
    show_copy_url: boolean;
}

export interface ThemeSettings {
    accentColor: string;
    headerBackgroundColor: string;
    headerLinkColor: string;
    showDate: boolean;
    showSubtitle: boolean;
    sharingActions: SocialNetwork[];
    sharingPlacement: SharingPlacement;
    showDownloadAssets: boolean;
    showCopyContent: boolean;
    showCopyUrl: boolean;
}
