import type { ThemeSettings, ThemeSettingsApiResponse } from 'types';

import { DEFAULT_THEME_SETTINGS } from './constants';

export function useThemeSettings(): ThemeSettings {
    const settings: ThemeSettingsApiResponse = DEFAULT_THEME_SETTINGS;

    return {
        accentColor: settings.accent_color,
        headerBackgroundColor: settings.header_background_color,
        headerLinkColor: settings.header_link_color,
        showDate: settings.show_date,
        showSubtitle: settings.show_subtitle,
        sharingActions: settings.sharing_actions,
        sharingPlacement: settings.sharing_placement,
        showCopyContent: settings.show_copy_content,
        showCopyUrl: settings.show_copy_url,
        showDownloadAssets: settings.show_download_assets,
    };
}
