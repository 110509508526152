import { SocialNetwork, type ThemeSettingsApiResponse } from 'types';

export const DEFAULT_THEME_SETTINGS: ThemeSettingsApiResponse = {
    accent_color: '#e20512',
    header_background_color: '#ffffff',
    header_link_color: '#e20512',
    show_date: true,
    show_subtitle: false,
    sharing_actions: [
        SocialNetwork.FACEBOOK,
        SocialNetwork.LINKEDIN,
        SocialNetwork.THREADS,
        SocialNetwork.WHATSAPP,
        SocialNetwork.TELEGRAM,
    ],
    sharing_placement: ['bottom'],
    show_copy_content: true,
    show_copy_url: true,
    show_download_assets: true,
};
